
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/studentviolations",
    "label": "Violations",
    "icon": "pi pi-stop-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Latest Violations",
    "icon": "pi pi-android",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/latestviolations",
        "label": "Latest Today",
        "icon": "pi pi-calendar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/violationtoday",
        "label": "Class Wise Today",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/codeofconduct",
        "label": "Conduct Wise",
        "icon": "pi pi-delete-left",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "System",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/rules",
        "label": "Rules",
        "icon": "pi pi-list",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/users",
        "label": "Users",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],
	class: [    
{value: "6th", label: "Class 6th"},
	{value: "7th", label: "Class 7th"},
	{value: "8th", label: "Class 8th"},
	{value: "9th", label: "Class 9th"}
    ],
	warnning: [    
{value: "Soft Warnning", label: "Soft Warnning"},
	{value: "Yellow Card", label: "Yellow Card"},
	{value: "Red Card", label: "Red Card"}
    ],
	notify: [    
{value: "Yes", label: "Yes"},
	{value: "No", label: "No"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}