
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//codeofconduct routes
			{
				path: '/codeofconduct/:fieldName?/:fieldValue?',
				name: 'codeofconductlist',
				component: () => import('./pages/codeofconduct/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//latestviolations routes
			{
				path: '/latestviolations/:fieldName?/:fieldValue?',
				name: 'latestviolationslist',
				component: () => import('./pages/latestviolations/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//rules routes
			{
				path: '/rules/:fieldName?/:fieldValue?',
				name: 'ruleslist',
				component: () => import('./pages/rules/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/rules/view/:id', 
				name: 'rulesview', 
				component: () => import('./pages/rules/view.vue'), 
				props: true
			},
		
			{ 
				path: '/rules/add', 
				name: 'rulesadd', 
				component: () => import('./pages/rules/add.vue'), 
				props: true
			},
	
			{ 
				path: '/rules/edit/:id', 
				name: 'rulesedit', 
				component: () => import('./pages/rules/edit.vue'), 
				props: true
			},
		

//studentviolations routes
			{
				path: '/studentviolations/:fieldName?/:fieldValue?',
				name: 'studentviolationslist',
				component: () => import('./pages/studentviolations/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/studentviolations/view/:id', 
				name: 'studentviolationsview', 
				component: () => import('./pages/studentviolations/view.vue'), 
				props: true
			},
		
			{ 
				path: '/studentviolations/add', 
				name: 'studentviolationsadd', 
				component: () => import('./pages/studentviolations/add.vue'), 
				props: true
			},
	
			{ 
				path: '/studentviolations/edit/:id', 
				name: 'studentviolationsedit', 
				component: () => import('./pages/studentviolations/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//violationtoday routes
			{
				path: '/violationtoday/:fieldName?/:fieldValue?',
				name: 'violationtodaylist',
				component: () => import('./pages/violationtoday/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
